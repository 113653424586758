nav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  nav a {
    text-decoration: none;
    color: #3498db;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  nav a:hover {
    color: #2980b9;
  }
  
  .social-icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }