.bio {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 0px;
  }
  
  .bio h2 {
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .bio p {
    color: #34495e;
    line-height: 1.6;
  }
  
  .contact-info {
    margin-top: 10px;
  }
  
  .contact-info h3 {
    color: #2c3e50;
    margin-bottom: 10x;
  }