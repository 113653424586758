header {
    text-align: center;
    padding: 40px 0;
    border-bottom: 1px solid #eaeaea;
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #2c3e50;
  }