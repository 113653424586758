.project {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.project:hover {
  transform: translateY(-5px);
}

.project h3 {
  font-size: 1.5rem;
  margin: 20px;
  color: #2c3e50;
}

.project p {
  margin: 0 20px 20px;
  color: #7f8c8d;
}

.project img,
.project iframe {
  width: 100%;
  height: 315px;
  object-fit: cover;
}

.project-link {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
}

.link-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: 2980b9;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.link-icon-button:hover {
  background-color: #2980b9;
}

.link-icon-button svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}