#projects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
  }
  
  h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 40px;
    color: #2c3e50;
    grid-column: 1 / -1;
  }